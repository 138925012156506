import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { genericResponse } from 'src/app/shared/models/generics';
import { ResponseBack } from '@core/models/responsehttp.model';

/******SERVICIO EXCLUSIVO PARA AUTOS */
@Injectable({
  providedIn: 'root',
})
export class AutosService {
  constructor(protected _http: HttpClient) {}

  // Listado de Autos
  /*public getAutos(pageSize: string, field: string): Observable<any> {
    return this._http
      .get(`${environment.WebApiUrlCarantyamdev}/cars/getCarsByPage?&pageSize=${pageSize}&field=${field}`)
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }*/

  //getCarantyCarsWithUserByPage
  public getAutosPage1(pageSize: number): Observable<any> {
    return this._http
      .get(`${environment.API_URL_MANAGEMENT}/cars/getCarantyCarsWithUserByPage?&pageSize=${pageSize}`)
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  public getAutosPage(pageSize: number, field_value: string = '', button: string = ''): Observable<any> {
    return this._http
      .get(
        `${environment.API_URL_MANAGEMENT}/cars/getCarantyCarsWithUserByPage?&pageSize=${pageSize}&field_value=${field_value}&button=${button}`
      )
      .pipe(map((response: genericResponse) => <genericResponse>response));
  }

  //Detalle Auto-Usuario
  /*   public getAutoUsuarioDetail(idCar: string): Observable<any> {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/cars/getcarantycarwithuser/${idCar}`)
      .pipe(map((response: genericResponse) => <genericResponse>response));
  } */

  /***LO QUE HABIA EN MESA DE CONTROL */

  public getAutosRegistrados(criteria: string, pageSize: number): Observable<any> {
    pageSize = pageSize === 0 ? 1000000000 : pageSize;
    return this._http.get(
      `${environment.API_URL_MANAGEMENT}/cars/getRegisteredCars?criteria=${criteria}&page=1&pageSize=${pageSize}`
    );
  }

  // Listado de Autos
  /*public getAutos(pageSize: string, field: string): Observable<any> {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/cars/getCarsByPage?&pageSize=${pageSize}&field=${field}`);
  }

  public getAutosPage(pageSize: number, field_value: string = '', button: string = ''): Observable<any> {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/cars/getCarantyCarsWithUserByPage?&pageSize=${pageSize}&field_value=${field_value}&button=${button}`);
  }

  //Detalle Auto-Usuario
/*   public getAutoUsuarioDetail(idCar: string): Observable<any> {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/cars/getcarantycarwithuser/${idCar}`)

  } */

  /*public getAutoUsuarioDetail(idCar: string): Observable<any> {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/cars/getDetailForAutosAndPublicaciones/${idCar}?flag_detail=autos`);
  }*/

  /***Por publicar */
  getInspeccionLegalComentario(carId: string) {
    return this._http.get(
      `${environment.API_URL_MANAGEMENT}/publicaciones/mesacontrol/comentarios/${carId}?backend=azure`
    );
  }

  postInspeccionLegalComentario(body) {
    return this._http.post(
      `${environment.API_URL_MANAGEMENT}/publicaciones/mesacontrol/comentarios?backend=azure`,
      body
    );
  }

  /***Autos dados de baja */
  getAutosDadosBajaList(criteria: string = '') {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('pagina_seleccionada', 1);
    httpParam = httpParam.append('elementos_pagina', 50);
    httpParam = httpParam.append('buscar', criteria);

    return this._http.get(`${environment.API_URL_MANAGEMENT}/cars/disabled?backend=azure`, { params: httpParam });
  }

  getDetalleAuto(carId: string) {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/cars/getcarantycarwithuser/${carId}`);
  }

  getPublicacionesByPage(page: number, limit?: number, criteria?: string, flag?: string) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('limit', limit);
    if (criteria) httpParam = httpParam.append('criteria', criteria ?? '');
    httpParam = httpParam.append('flag', flag ?? '');

    return this._http.get(`${environment.API_URL_MANAGEMENT}/cars/getPublicacionesByPage/${page}`, {
      params: httpParam,
    });
  }

  /**
   * Regresa el detalle del auto sleccionado
   * @param id
   * @param field_value --> "publicados" "por_publicar" "offers" "por_publicar_crm"
   * @returns
   */
  getDetalleAutoRegistrado(id: string = '', field_value: string = '') {
    return this._http.get<ResponseBack>(
      `${environment.API_URL_MANAGEMENT}/cars/car/getCarForOffersPublishedAndNotPublished/detail/${id}?flag_detail=${field_value}`
    );
  }

  /**
   * Editar Auto
   * @param id  --> id del auto
   * @param bodyCampos  --> Campos a editar
   * @returns
   */
  putAuto(id: string, body: any) {
    return this._http.put(`${environment.API_URL_MANAGEMENT}/cars/car/${id}`, body);
  }

  getDetailForAutosAndPublicaciones(carId: string, flag_detail: string = 'autos') {
    //flag_detail=autos
    return this._http.get<ResponseBack>(
      `${environment.API_URL_MANAGEMENT}/cars/getDetailForAutosAndPublicaciones/${carId}?flag_detail=${flag_detail}`
    );
  }

  getUsersWithCarsReserved() {
    return this._http.get<ResponseBack>(`${environment.API_URL_MANAGEMENT}/admin/users/autosApartados`);
  }

  getUsersWithCarsReservedDetail(idUser: string) {
    return this._http.get<ResponseBack>(`${environment.API_URL_MANAGEMENT}/admin/users/apartados/${idUser}`);
  }

  postMovedReservedCar(idCar: string, idBuyer: string, newCar: string) {
    return this._http.post<ResponseBack>(`${environment.API_URL_MANAGEMENT}/admin/payments/moveAmount/apartado`, {
      idCar,
      idBuyer,
      newCar,
    });
  }
}
